import { createSlice } from '@reduxjs/toolkit';
import apiSlice from '../apiSlice';
import {
	ChangePassword,
	CreateNewUser,
	ForgotPassword,
	GetAllUsers,
	GetSellerListForDropDown,
	UpdateForgotPassword,
	getLogisticsPartners,
	getWareHouseInfo,
	loginUrl,
} from '../url';

export const userApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (credentials) => {
				return {
					url: loginUrl,
					method: 'POST',
					body: credentials,
				};
			},
		}),
		forgotPassword: builder.mutation({
			query: (payload) => {
				return {
					url: ForgotPassword,
					method: 'POST',
					body: payload,
				};
			},
		}),
		resetPassword: builder.mutation({
			query: (payload = {}) => {
				return {
					url: UpdateForgotPassword,
					method: 'POST',
					body: payload,
				};
			},
		}),
		updatePassword: builder.mutation({
			query: (payload = {}) => {
				return {
					url: ChangePassword,
					method: 'POST',
					body: payload,
				};
			},
		}),
		//Seller DropDown
		getAllSellersList: builder.mutation({
			query: () => {
				return {
					url: GetSellerListForDropDown,
				};
			},
		}),
		createUser: builder.mutation({
			query: (payload = {}) => {
				return {
					url: CreateNewUser,
					method: 'POST',
					body: payload,
				};
			},
			providesTags: ['Users'],
		}),
		getAllUsers: builder.query({
			query: ({ pageNum = 1, pageSize = 10 }) => {
				return {
					url: `${GetAllUsers}?pageNum=${pageNum}&pageSize=${pageSize}`,
				};
			},
			invalidatesTags: ['Users'],
		}),
		// Get Seller Ware house Info
		getWareHouseInfo: builder.mutation({
			query: ({ vendorId = '', logisticPartnerId = '' }) => {
				return {
					url: `${getWareHouseInfo}?vendorId=${vendorId}&logisticPartnerId=${logisticPartnerId}`,
				};
			},
		}),
		//Logistics Partners DropDown
		getLogisticsPartners: builder.mutation({
			query: () => {
				return {
					url: getLogisticsPartners,
				};
			},
		}),
	}),
});

const slice = createSlice({
	name: 'auth',
	initialState: {
		user: {},
		userRole: '',
		sellerDropDown: [
			{
				label: 'all',
				value: 'all,',
			},
		],
		filters: {},
		wareHouseInformation: [],
		logisticsPartners: [],
		selectedSeller: {},
		prevSelectedSeller: {},
		OrderPageDatefilters: {},
		OrderPageSize: 20,
		ReconciliationDateFilters: {},
		ReconciliationPageSize: 50,
	},
	reducers: {
		updateAdminSelectedSeller: (state, action) => {
			state.prevSelectedSeller = state.selectedSeller;
			state.selectedSeller = action?.payload;
		},
		updateUserState: (state, { payload: { user } }) => {
			//localStorage.setItem('auth', JSON.stringify(user));
			state.user = user;
		},
		updateUserRole: (state, { payload = '' }) => {
			state.userRole = payload;
		},
		loadAuthFromLocalStorage: (state) => {
			const extAuthStr = localStorage.getItem('auth');
			const extAuthJson = JSON.parse(extAuthStr);
			if (extAuthStr) {
				state.user = extAuthJson;
			}
		},
		logout: (state) => {
			localStorage.clear();
			state.ReconciliationDateFilters = {};
			state.ReconciliationPageSize = 50;
			state.OrderPageDatefilters = {};
			state.OrderPageSize = 20;
			state.selectedSeller = {};
			state.prevSelectedSeller = {};
			state.user = null;
			state.userRole = '';
			state.sellerDropDown = [
				{
					label: 'all',
					value: 'all,',
				},
			];
		},
		updateFilters: (state, action) => {
			state.OrderPageDatefilters = action?.payload;
		},
		updateOrderPageSize: (state, action) => {
			state.OrderPageSize = action?.payload || 20;
		},
		updateReconciliationDateFilters: (state, action) => {
			state.ReconciliationDateFilters = action?.payload;
		},
		updateReconciliationPageSize: (state, action) => {
			state.ReconciliationPageSize = action?.payload || 50;
		},
		resetWareHouseInformation: (state, action) => {
			state.wareHouseInformation = [];
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			apiSlice.endpoints.getAllSellersList.matchFulfilled,
			(state, action) => {
				const convertDataFormatWithAll = (data) => {
					const result =
						data &&
						data.map((item) => ({
							label: item.vendorName,
							value: item.id,
						}));

					result.unshift({
						label: 'all',
						value: 'all',
					});

					return result || [];
				};
				// const buildDropDownPayload = convertDataFormatWithAll(action?.payload);
				let respPayload = action?.payload || [];
				let payload = [
					{
						label: 'all',
						value: 'all',
					},
					...respPayload,
				];
				state.sellerDropDown = payload;
			}
		);
		builder.addMatcher(
			apiSlice.endpoints.getWareHouseInfo.matchFulfilled,
			(state, action) => {
				state.wareHouseInformation = action?.payload || [];
			}
		);
		builder.addMatcher(
			apiSlice.endpoints.getLogisticsPartners.matchFulfilled,
			(state, action) => {
				state.logisticsPartners = action?.payload || [];
			}
		);
	},
});

// useLoginMutation
export const {
	useLoginMutation,
	useForgotPasswordMutation,
	useResetPasswordMutation,
	useUpdatePasswordMutation,
	useGetAllSellersListMutation,
	useCreateUserMutation,
	useGetAllUsersQuery,
	useGetWareHouseInfoMutation,
	useGetLogisticsPartnersMutation,
} = userApiSlice;

export const {
	updateUserState,
	updateUserRole,
	loadAuthFromLocalStorage,
	logout,
	updateFilters,
	resetWareHouseInformation,
	updateAdminSelectedSeller,
	updateOrderPageSize,
	updateReconciliationDateFilters,
	updateReconciliationPageSize,
} = slice.actions;

export const authReducer = slice.reducer;

export const getCurrentUser = (state) => state.auth.user || {};
export const getCurrentUserRole = (state) => state.auth.userRole || '';
export const getSellerList = (state) => state.auth.sellerDropDown || [];
export const getUserFilters = (state) => state.auth.OrderPageDatefilters || {};
export const getWareHouseInformationOfSeller = (state) =>
	state.auth.wareHouseInformation || [];
export const getLogisticsPartnersList = (state) =>
	state.auth.logisticsPartners || [];
export const getUpdatedAdminSeller = (state) => state.auth.selectedSeller || {};
export const getPreviouslySelecetedSeller = (state) =>
	state.auth.prevSelectedSeller || {};
export const getOrderPageSize = (state) => state.auth.OrderPageSize || 20;
export const getReconciliationDateFilters = (state) =>
	state.auth.ReconciliationDateFilters || {};
export const getReconciliationPageSize = (state) =>
	state.auth.ReconciliationPageSize || 50;
