import React, { lazy, Suspense, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Loader from './components/Loader';
import './index.css';

const Login = lazy(() => import('./pages/Auth/Login'));
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'));
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const Warehouse = lazy(() => import('./pages/Orders/Warehouse'));
const RequireAuth = lazy(() => import('./components/RequireAuth'));
const Orders = lazy(() => import('./pages/Orders/Orders'));
const Sellers = lazy(() => import('./pages/Seller/Sellers'));
const OrderDetails = lazy(() => import('./pages/Orders/OrderDetails'));
const Products = lazy(() => import('./pages/Products/Products'));
const ProductDetails = lazy(() => import('./pages/Products/ProductDetails'));
const AdminReconciliationReport = lazy(() =>
	import('./pages/ReconciliationReport')
);

const Users = lazy(() => import('./pages/Users/Users'));
const UserCreation = lazy(() => import('./pages/Users/UserCreation'));
const AddSeller = lazy(() => import('./pages/Seller/AddSeller'));
const SellerCommission = lazy(() => import('./pages/Seller/SellerCommission'));
const Inventory = lazy(() => import('./pages/Seller/Inventory'));
const EditSeller = lazy(() => import('./pages/Seller/EditSeller'));
const Manifests = lazy(() => import('./pages/Manifests/Manifests'));
const SellerManifests = lazy(() => import('./pages/Manifests/SellerManifests'));
const App = () => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		const handleStatusChange = () => {
			setIsOnline(navigator.onLine);
		};
		window.addEventListener('online', handleStatusChange);
		window.addEventListener('offline', handleStatusChange);
		return () => {
			window.removeEventListener('online', handleStatusChange);
			window.removeEventListener('offline', handleStatusChange);
		};
	}, []);

	useEffect(() => {
		if (isOnline) {
			toast.success('App is Online');
		} else {
			toast.error('App is offline please check your internet connection');
		}
	}, [isOnline]);

	return (
		<BrowserRouter>
			<Suspense
				fallback={
					<div>
						<Loader />
					</div>
				}
			>
				<Routes>
					{/* <Route path="/Loading" element={<Loading />} /> */}
					<Route path="/" element={<Login />} />
					<Route path="login" element={<Login />} />
					<Route path="reset-password" element={<ResetPassword />} />
					<Route path="forgot-password" element={<ForgotPassword />} />

					<Route path="sid" element={<RequireAuth />}>
						{/* <Route path="userprofile/:id" element={<UserProfile />} /> */}
						<Route path="userprofile/:id" element={<EditSeller />} />
						<Route path="warehouse" element={<Warehouse />} />
						<Route path="sellers" element={<Sellers />} />
						<Route path="orders" element={<Orders />} />
						<Route path="orders/:id" element={<OrderDetails />} />
						<Route path="products" element={<Products />} />
						<Route path="products/:id" element={<ProductDetails />} />
						<Route path="users" element={<Users />} />
						<Route path="createUser" element={<UserCreation />} />
						<Route path="addSeller" element={<AddSeller />} />
						<Route path="addSeller/:id" element={<AddSeller />} />
						<Route path="editSeller/:id" element={<EditSeller />} />
						<Route path="SellerCommission" element={<SellerCommission />} />
						<Route path="inventory" element={<Inventory />} />
						<Route path="manifests" element={<Manifests />} />
						<Route path="sellerManifests" element={<SellerManifests />} />
						<Route
							path="reconciliationReport"
							element={<AdminReconciliationReport />}
						/>
					</Route>
				</Routes>
				<Toaster />
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
