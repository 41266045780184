import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiBaseUrl } from '../../util/envConfig';

const apiSlice = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: apiBaseUrl,
		prepareHeaders: async (headers, { getState }) => {
			if (
				!(
					headers.has('skipJsonContentHeader') &&
					headers.get('skipJsonContentHeader')
				)
			) {
				headers.set('Content-Type', 'application/json');
			}
			headers.set('Accept', '*/*');
			const token = getState()?.auth?.user?.accessToken;
			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
		tagTypes: ['SellerProducts', 'SellerOrders', 'Users'],
		keepUnusedDataFor: 0.0001,
	}),
	endpoints: () => ({}),
});

export default apiSlice;
