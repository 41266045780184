import React from 'react';
import Loading from './Loading';

const Loader = (props) => {
	return (
		<section className="w-full h-screen flex items-center justify-center bg-gradient-to-tr from-primary via-primary to-gradientEndGreen">
			<div>
				<Loading fullScreenMode color="white" />
			</div>
		</section>
	);
};

export default Loader;
