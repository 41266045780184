// Admin URLS
const AdminOrdersUrl = 'admin/orders/all';
const AdminProductsUrl = 'admin/products/all';
const GetAllSellers = 'admin/sellers/all';

const GetLogisticFiles = 'seller/logistic/files';
const UploadLogisticFiles = 'admin/sellers/upload/logistics/files';
const FileDownloadStatus = 'seller/update/filedownload/status';
const DeleteDocument = 'seller/delete/file';

const CreateErrorLog = 'LogError/mp-create-error-log';

//seller commissions

const UpdateCommissionsOrder = 'commissions/update/order/products';

const addVendorCommission = 'commissions/add/vendor';
const GetVendorProductsByID = 'commissions/get/vendor/products';
const UpdateVenorProducts = 'commissions/update/vendor/products';
const updateVendorCommission = 'commissions/update/vendor';
const getVendorCommission = 'commissions/get/vendor';

const getReconcilationReport = 'commissions/get/report/reconcilation';

//api/commissions/get/vendor?vendorId=659b9cd635333f16028e4980

//pin code
const GetPincodeDetails = 'seller/pincode';

// Vendor logo upload
const UploadLogo = 'seller/upload/file';

// Seller URLS
const SellerOrdersUrl = 'seller/orders';
const getOrderByOrderId = 'seller/order/id';
const SellerProductsUrl = 'seller/products';
const GetSellerInfo = 'seller/id';
const GetSellerDetailsById = 'seller/details';
const GetCustomerInvoice = `invoice/download`;
const UpdateProductInventory = `seller/update/product`;
const UpdateTrackingInfo = `order/tracking/info/update`;
const getWareHouseInfo = 'seller/warehouse/information';
const getLogisticsPartners = 'logistics/partners';
const createNewSeller = 'seller/save';
const updateSellerInfo = 'seller/details/update';
const orderFulfillment = 'seller/update/fulfilment/order';
// Search URLs
const SearchOrdersUrl = 'seller/search/orders';
const SearchProductsUrl = 'seller/search/product';

// User Context Urls
const loginUrl = 'auth/login';
const ForgotPassword = 'auth/forgot/password';
const UpdateForgotPassword = 'auth/update/password';
const ChangePassword = 'auth/change/password';
const GetSellerListForDropDown = `seller/list`;
const CreateNewUser = 'auth/create/user';
const GetAllUsers = 'auth/all/users';

// report
const getAdminRecouncillationReport =
	'commissions/get/report/reconcilation/all';

// Logistics URL for Manifest & label generation
const generateManifest = 'logistics/create/manifest';
const generateLabel = 'logistics/generate/label';

export {
	AdminOrdersUrl,
	AdminProductsUrl,
	ChangePassword,
	CreateErrorLog,
	CreateNewUser,
	DeleteDocument,
	FileDownloadStatus,
	ForgotPassword,
	GetAllSellers,
	GetAllUsers,
	GetCustomerInvoice,
	GetLogisticFiles,
	GetPincodeDetails,
	GetSellerDetailsById,
	GetSellerInfo,
	GetSellerListForDropDown,
	GetVendorProductsByID,
	SearchOrdersUrl,
	SearchProductsUrl,
	SellerOrdersUrl,
	SellerProductsUrl,
	UpdateCommissionsOrder,
	UpdateForgotPassword,
	UpdateProductInventory,
	UpdateTrackingInfo,
	UpdateVenorProducts,
	UploadLogisticFiles,
	UploadLogo,
	addVendorCommission,
	createNewSeller,
	generateLabel,
	generateManifest,
	getAdminRecouncillationReport,
	getLogisticsPartners,
	getOrderByOrderId,
	getReconcilationReport,
	getVendorCommission,
	getWareHouseInfo,
	loginUrl,
	orderFulfillment,
	updateSellerInfo,
	updateVendorCommission,
};
