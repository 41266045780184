import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
//import storage from 'redux-persist/lib/storage/session';

import apiSlice from './api/apiSlice';
import { authReducer } from './api/slices/userApiSlice';
import { sellerReducer } from './api/slices/sellerApiSlice';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	whitelist: ['auth'],
};

const rootReducerConfig = combineReducers({
	[apiSlice.reducerPath]: apiSlice.reducer,
	auth: authReducer,
	sellers: sellerReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducerConfig);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(apiSlice.middleware),
	devTools: true,
});

const persistor = persistStore(store);

export { persistor, store };
