import { createSlice } from '@reduxjs/toolkit';
import { endOfDay, format, startOfDay } from 'date-fns';
import apiSlice from '../apiSlice';
// import { sellersData } from './sellers.js';

import {
	AdminOrdersUrl,
	AdminProductsUrl,
	CreateErrorLog,
	DeleteDocument,
	FileDownloadStatus,
	GetAllSellers,
	GetCustomerInvoice,
	GetLogisticFiles,
	GetPincodeDetails,
	GetSellerDetailsById,
	GetSellerInfo,
	GetVendorProductsByID,
	SearchOrdersUrl,
	SearchProductsUrl,
	SellerOrdersUrl,
	SellerProductsUrl,
	UpdateCommissionsOrder,
	UpdateProductInventory,
	UpdateVenorProducts,
	UploadLogisticFiles,
	UploadLogo,
	addVendorCommission,
	createNewSeller,
	generateLabel,
	generateManifest,
	getAdminRecouncillationReport,
	getOrderByOrderId,
	getReconcilationReport,
	getVendorCommission,
	orderFulfillment,
	updateSellerInfo,
	updateVendorCommission,
} from '../url';

export const sellerApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		// Get Seller Orders
		getSellerOrders: builder.query({
			query: (queryOptions) => {
				const {
					vendorName = '',
					pageNum = 1,
					pageSize = 20,
					ranges,
					isAdminUser,
					isScmUser = false,
				} = queryOptions;
				const URLBase =
					isAdminUser || isScmUser ? AdminOrdersUrl : SellerOrdersUrl;
				const queryParameters = [
					`pageNum=${pageNum}`,
					`pageSize=${pageSize}`,
					`vendorName=${vendorName}`,
				];

				if (ranges && ranges.length > 0) {
					const startDate = new Date(ranges[0].startDate);
					const endDate = new Date(ranges[0].endDate);
					const formattedStartDate = format(startDate, 'yyyy-MM-dd');
					const formattedEndDate = format(endDate, 'yyyy-MM-dd');
					queryParameters.push(
						`fromDate=${formattedStartDate}`,
						`toDate=${formattedEndDate}`
					);
				}
				const URL = `${URLBase}?${queryParameters.join('&')}`;
				return {
					url: URL,
					keepUnusedDataFor: 0,
				};
			},
			providesTags: ['SellerOrders'],
		}),
		// Get Order By Search
		getOrderById: builder.mutation({
			query: ({
				vendorName = '',
				orderId = '',
				pageNum = 1,
				pageSize = 20,
			}) => {
				return {
					url: `${SearchOrdersUrl}?orderId=${orderId}&pageNum=${pageNum}&pageSize=${pageSize}&vendorName=${vendorName}`,
					keepUnusedDataFor: 0,
				};
			},
		}),

		getProductByName: builder.mutation({
			query: ({
				vendorName = '',
				productName = '',
				pageNum = 1,
				pageSize = 20,
			}) => {
				return {
					url: `${SearchProductsUrl}?productName=${productName}&pageNum=${pageNum}&pageSize=${pageSize}&vendorName=${vendorName}`,
					//keepUnusedDataFor: 0.0001,
				};
			},
		}),
		// Get Seller Products
		getSellerProducts: builder.query({
			query: (queryOptions) => {
				const {
					vendorName = '',
					pageNum = 1,
					pageSize = 20,
					isAdminUser,
				} = queryOptions;

				const baseRoute = isAdminUser ? AdminProductsUrl : SellerProductsUrl;
				const commonParams = `vendorName=${vendorName}&pageNum=${pageNum}&pageSize=${pageSize}`;

				const URL = `${baseRoute}?${commonParams}`;

				return {
					url: URL,
					keepUnusedDataFor: 0,
				};
			},
			providesTags: ['SellerProducts'],
		}),
		// Get Seller Info By ID
		getSellerById: builder.mutation({
			query: ({ sellerId = '' }) => {
				return {
					url: `${GetSellerInfo}?Id=${sellerId}`,
					keepUnusedDataFor: 0,
				};
			},
		}),
		getSellerDetailsByID: builder.query({
			query: ({ sellerId = '' }) => ({
				url: `${GetSellerDetailsById}?Id=${sellerId}`,
				method: 'GET',
			}),
			keepUnusedDataFor: 0,
		}),

		// Update Product inventory QTY
		updateProductInventoryQty: builder.mutation({
			query: ({ payload = {} }) => {
				return {
					url: UpdateProductInventory,
					method: 'PUT',
					body: payload,
				};
			},
			invalidatesTags: ['SellerProducts'],
		}),

		// Update Fulfillment Info
		updateOrderTrackingInfo: builder.mutation({
			query: ({ payload = {} }) => {
				return {
					url: orderFulfillment,
					method: 'PUT',
					body: payload,
				};
			},
			invalidatesTags: ['SellerOrders'],
		}),
		// get Seller Orders Excel data
		getSellerOrdersForExcel: builder.mutation({
			query: ({
				vendorName = '',
				pageNum = 1,
				pageSize = 20,
				ranges,
				isAdminUser,
			}) => {
				const queryParameters = [
					`pageNum=${pageNum}`,
					`pageSize=${pageSize}`,
					`vendorName=${vendorName}`,
				];
				if (ranges && ranges.length > 0) {
					const startDate = new Date(ranges[0].startDate);
					const endDate = new Date(ranges[0].endDate);
					const formattedStartDate = format(startDate, 'yyyy-MM-dd');
					const formattedEndDate = format(endDate, 'yyyy-MM-dd');
					queryParameters.push(
						`fromDate=${formattedStartDate}`,
						`toDate=${formattedEndDate}`
					);
				}

				const baseRoute = isAdminUser ? AdminOrdersUrl : SellerOrdersUrl;
				const URL = `${baseRoute}?${queryParameters.join('&')}`;
				return {
					url: URL,
				};
			},
		}),
		// get Customer Invoice
		getCustomerInvoiceforOrder: builder.mutation({
			query: ({ payload = {} }) => {
				return {
					url: GetCustomerInvoice,
					method: 'POST',
					body: payload,
				};
			},
		}),
		createSeller: builder.mutation({
			query: (payload = {}) => {
				return {
					url: createNewSeller,
					method: 'POST',
					body: payload,
				};
			},
			providesTags: ['Sellers'],
		}),
		updateSeller: builder.mutation({
			query: (payload = {}) => {
				return {
					url: updateSellerInfo,
					method: 'PUT',
					body: payload,
				};
			},
			providesTags: ['Sellers'],
		}),
		// Seller Context

		getAllSellers: builder.query({
			query: ({ pageNum = 1, pageSize = 10 }) => ({
				url: `${GetAllSellers}?pageNum=${pageNum}&pageSize=${pageSize}`,
				method: 'GET',
			}),
			keepUnusedDataFor: 0,
		}),

		// Get Pin Code Details
		getPincodeDetails: builder.query({
			query: ({ pinCode }) => {
				return {
					url: `${GetPincodeDetails}/${pinCode}`,
				};
			},
		}),

		// Generate Manifest
		generateManifest: builder.mutation({
			query: ({ payload = {} }) => {
				return {
					url: generateManifest,
					method: 'POST',
					body: payload,
				};
			},
		}),

		// Get logestics label
		generateLabel: builder.mutation({
			query: ({ orderId = '', manifestId = '', logisticPartnerName = '' }) => {
				const encodedOrderId = encodeURIComponent(orderId);
				const encodedManifestId = encodeURIComponent(manifestId);
				return {
					url: `${generateLabel}?orderId=${encodedOrderId}&manifestId=${encodedManifestId}&logisticPartnerName=${logisticPartnerName}`,
					method: 'GET',
				};
			},
		}),
		// Get Order By Order Id
		getOrderByOrderId: builder.mutation({
			query: ({ orderId = '', vendorName = 'All' }) => {
				const encodedOrderId = encodeURIComponent(orderId);
				const encodedVendorName = encodeURIComponent(vendorName);
				return {
					url: `${getOrderByOrderId}?orderId=${encodedOrderId}&vendor=${encodedVendorName}`,
					method: 'GET',
				};
			},
		}),
		addVendorCommission: builder.mutation({
			query: (payload = {}) => {
				return {
					url: addVendorCommission,
					method: 'POST',
					body: payload,
				};
			},
		}),

		// update Vendor Commission
		updateVendorCommission: builder.mutation({
			query: (payload = {}) => {
				return {
					url: updateVendorCommission,
					method: 'PUT',
					body: payload,
				};
			},
		}),

		getVendorProductsByID: builder.mutation({
			query: ({ vendorId = '' }) => {
				return {
					url: `${GetVendorProductsByID}?vendorId=${vendorId}`,
					keepUnusedDataFor: 0,
				};
			},
		}),
		updateVenorProducts: builder.mutation({
			query: ({ payload = {} }) => {
				return {
					url: UpdateVenorProducts,
					method: 'PUT',
					body: payload,
				};
			},
		}),
		updateCommissionsOrder: builder.mutation({
			query: ({ payload = {} }) => {
				return {
					url: UpdateCommissionsOrder,
					method: 'PUT',
					body: payload,
				};
			},
		}),
		//Logo Upload
		logoUpload: builder.mutation({
			query: ({ payload = {}, type = '' }) => {
				return {
					url: `${UploadLogo}?type=${type}`,
					method: 'POST',
					body: payload,
					headers: {
						skipJsonContentHeader: true,
					},
				};
			},
		}),

		// get vendor Commision
		// vendorCommisionBysellerId: builder.query({
		// 	query: ({ sellerId = '' }) => {
		// 		return {
		// 			url: `${getVendorCommission}?vendorId=${sellerId}`,
		// 		};
		// 	},
		// }),

		fileDownloadStatusUpdate: builder.mutation({
			query: ({ id = '', vendorId = '' }) => {
				return {
					url: `${FileDownloadStatus}?id=${id}&vendorId=${vendorId}`,
					method: 'PUT',
				};
			},
		}),

		getVendorCommisionBysellerId: builder.query({
			query: ({ vendorId = '' }) => ({
				url: `${getVendorCommission}?vendorId=${vendorId}`,
				method: 'GET',
			}),
			keepUnusedDataFor: 0,
		}),

		getVendorReconcilationReport: builder.query({
			query: ({ vendorName = '', ranges = [], pageNum, pageSize }) => {
				const queryParameters = [
					`vendorName=${vendorName}`,
					`pageNum=${pageNum}`,
					`pageSize=${pageSize}`,
				];

				if (ranges && ranges.length > 0) {
					const startDate = new Date(ranges[0].startDate);
					const endDate = new Date(ranges[0].endDate);
					const startOfDayDate = startOfDay(startDate);
					const endOfDayDate = endOfDay(endDate);
					const formattedStartDate = format(startOfDayDate, 'yyyy-MM-dd');
					const formattedEndDate = format(endOfDayDate, 'yyyy-MM-dd');
					queryParameters.push(
						`fromDate=${formattedStartDate}`,
						`toDate=${formattedEndDate}`
					);
				}

				//fromDate=2024-02-02%2012:00:00&toDate=2024-02-02%2011:59:59

				return {
					url: `${getReconcilationReport}?${queryParameters.join('&')}`,
					method: 'GET',
				};
			},
		}),
		getVendorReconcilationReportToExcel: builder.mutation({
			query: ({ vendorName = '', ranges = [], pageNum, pageSize }) => {
				const queryParameters = [
					`vendorName=${vendorName}`,
					`pageNum=${pageNum}`,
					`pageSize=${pageSize}`,
				];

				if (ranges && ranges.length > 0) {
					const startDate = new Date(ranges[0].startDate);
					const endDate = new Date(ranges[0].endDate);
					const startOfDayDate = startOfDay(startDate);
					const endOfDayDate = endOfDay(endDate);
					const formattedStartDate = format(startOfDayDate, 'yyyy-MM-dd');
					const formattedEndDate = format(endOfDayDate, 'yyyy-MM-dd');
					queryParameters.push(
						`fromDate=${formattedStartDate}`,
						`toDate=${formattedEndDate}`
					);
				}

				return {
					url: `${getReconcilationReport}?${queryParameters.join('&')}`,
					method: 'GET',
				};
			},
		}),
		getLogisticFiles: builder.query({
			query: ({ vendorId = '', ranges = '', pageNum = 1, pageSize = 20 }) => {
				const queryParameters = [
					`vendorId=${vendorId}`,
					`pageNum=${pageNum}`,
					`pageSize=${pageSize}`,
				];
				if (ranges && ranges.length > 0) {
					const startDate = new Date(ranges[0].startDate);
					const endDate = new Date(ranges[0].endDate);
					const formattedStartDate = format(startDate, 'yyyy-MM-dd');
					const formattedEndDate = format(endDate, 'yyyy-MM-dd');
					queryParameters.push(
						`fromDate=${formattedStartDate}`,
						`toDate=${formattedEndDate}`
					);
				}
				return {
					url: `${GetLogisticFiles}?${queryParameters.join('&')}`,
					method: 'GET',
				};
			},
			keepUnusedDataFor: 0,
		}),
		logisticFileUpload: builder.mutation({
			query: ({
				payload = {},
				vendorId = '',
				logisticPartnerId = '',
				documentType = '',
				notes = '',
			}) => {
				const encodeOrders = notes.length ? encodeURIComponent(notes) : '';
				return {
					url: `${UploadLogisticFiles}?vendorId=${vendorId}&logisticPartnerId=${logisticPartnerId}&documentType=${documentType}&notes=${encodeOrders}`,
					method: 'POST',
					body: payload,
					headers: {
						skipJsonContentHeader: true,
					},
				};
			},
		}),
		logisticFileDelete: builder.mutation({
			query: ({ documentId = '' }) => {
				return {
					url: `${DeleteDocument}?documentId=${documentId}`,
					method: 'DELETE',
				};
			},
		}),
		createErrorLog: builder.mutation({
			query: (payload = {}) => {
				return {
					url: CreateErrorLog,
					method: 'POST',
					body: payload,
				};
			},
		}),

		getAdminReconcilationReport: builder.query({
			query: ({ ranges = [], pageNum, pageSize }) => {
				const queryParameters = [`pageNum=${pageNum}`, `pageSize=${pageSize}`];

				if (ranges && ranges.length > 0) {
					const startDate = new Date(ranges[0].startDate);
					const endDate = new Date(ranges[0].endDate);
					const startOfDayDate = startOfDay(startDate);
					const endOfDayDate = endOfDay(endDate);
					const formattedStartDate = format(startOfDayDate, 'yyyy-MM-dd');
					const formattedEndDate = format(endOfDayDate, 'yyyy-MM-dd');
					queryParameters.push(
						`fromDate=${formattedStartDate}`,
						`toDate=${formattedEndDate}`
					);
				}

				return {
					url: `${getAdminRecouncillationReport}?${queryParameters.join('&')}`,
					method: 'GET',
				};
			},
		}),

		getAdminReconcilationReportToExcel: builder.mutation({
			query: ({ ranges = [], pageNum, pageSize }) => {
				const queryParameters = [`pageNum=${pageNum}`, `pageSize=${pageSize}`];

				if (ranges && ranges.length > 0) {
					const startDate = new Date(ranges[0].startDate);
					const endDate = new Date(ranges[0].endDate);
					const startOfDayDate = startOfDay(startDate);
					const endOfDayDate = endOfDay(endDate);
					const formattedStartDate = format(startOfDayDate, 'yyyy-MM-dd');
					const formattedEndDate = format(endOfDayDate, 'yyyy-MM-dd');
					queryParameters.push(
						`fromDate=${formattedStartDate}`,
						`toDate=${formattedEndDate}`
					);
				}

				return {
					url: `${getAdminRecouncillationReport}?${queryParameters.join('&')}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const {
	useGetSellerOrdersQuery,
	useGetSellerOrdersForExcelMutation,
	useGetOrderByIdMutation,
	useGetSellerProductsQuery,
	useGetSellerByIdMutation,
	useGetSellerDetailsByIDQuery,
	useCreateSellerMutation,
	useUpdateSellerMutation,
	useGetLogisticFilesQuery,
	useLogisticFileDeleteMutation,
	useGetAllSellersQuery,
	useGetPincodeDetailsQuery,
	useGetProductByNameMutation,
	useUpdateProductInventoryQtyMutation,
	useUpdateOrderTrackingInfoMutation,
	useGetCustomerInvoiceforOrderMutation,
	useGenerateManifestMutation,
	useGenerateLabelMutation,
	useGetOrderByOrderIdMutation,
	useAddVendorCommissionMutation,
	useUpdateVendorCommissionMutation,
	useGetVendorProductsByIDMutation,
	useUpdateVenorProductsMutation,
	useUpdateCommissionsOrderMutation,
	useLogoUploadMutation,
	useGetVendorCommisionBysellerIdQuery,
	useGetVendorReconcilationReportQuery,
	useLogisticFileUploadMutation,
	useFileDownloadStatusUpdateMutation,
	useGetVendorReconcilationReportToExcelMutation,
	useCreateErrorLogMutation,
	useGetAdminReconcilationReportQuery,
	useGetAdminReconcilationReportToExcelMutation,
} = sellerApiSlice;

const sellerSlice = createSlice({
	name: 'sellers',
	initialState: {
		orders: [],
		orderDetail: {},
		products: [],
		seller: [],
		allSellers: [],
		sellerPageNum: 1,
		sellerPageSize: 20,
		usersPageNum: 1,
		usersPageSize: 20,
	},
	reducers: {
		setSellerPageNum: (state, action) => {
			state.sellerPageNum = action?.payload;
		},
		setSellerPageSize: (state, action) => {
			state.sellerPageSize = action?.payload;
		},
		setUsersPageNum: (state, action) => {
			state.usersPageNum = action?.payload;
		},
		setUsersPageSize: (state, action) => {
			state.usersPageSize = action?.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			apiSlice.endpoints.getSellerOrders.matchFulfilled,
			(state, action) => {
				state.orders = action?.payload || [];
			}
		);
		builder.addMatcher(
			apiSlice.endpoints.getOrderByOrderId.matchFulfilled,
			(state, action) => {
				state.orderDetail = action?.payload || [];
			}
		);
		builder.addMatcher(
			apiSlice.endpoints.getSellerProducts.matchFulfilled,
			(state, action) => {
				state.products = action?.payload || [];
			}
		);
		builder.addMatcher(
			apiSlice.endpoints.getSellerById.matchFulfilled,
			(state, action) => {
				const resp = action?.payload;
				localStorage.setItem('sellerDetails', JSON.stringify(resp));
				state.seller = action?.payload || [];
			}
		);
		builder.addMatcher(
			apiSlice.endpoints.getAllSellers.matchFulfilled,
			(state, action) => {
				state.allSellers = action?.payload || [];
			}
		);
	},
});

export const {
	setSellerPageNum,
	setSellerPageSize,
	setUsersPageNum,
	setUsersPageSize,
} = sellerSlice.actions;

export const sellerReducer = sellerSlice.reducer;

export const getSellerProducts = (state) => state.sellers.products || [];
export const getSellerOrders = (state) => state.sellers.orders || [];
export const getSellerDetails = (state) => state.sellers.seller || [];
export const getAllSellersDetails = (state) => state.sellers.allSellers || [];

export const getOrderDetailFromState = (state) =>
	state.sellers.orderDetail || {};

export const getSellersPageNumFromState = (state) =>
	state.sellers.sellerPageNum || [];
export const getSellerPageSizeFromState = (state) =>
	state.sellers.sellerPageSize || [];
export const getUsersPageNumFromState = (state) =>
	state.sellers.usersPageNum || [];
export const getUsersPageSizeFromState = (state) =>
	state.sellers.usersPageSize || [];
