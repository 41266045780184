const environments = {
	dev: {
		apiBaseUrl: 'https://mpdev.devbh.in/marketplace/api/',
		blobBaseUrl: 'https://dev-qa-bighaat-media-cdn.azureedge.net/',
	},
	qa: {
		apiBaseUrl: 'https://mp.qabh.in/marketplace/api/',
		blobBaseUrl: 'https://dev-qa-bighaat-media-cdn.azureedge.net/',
	},
	beta: {
		apiBaseUrl: 'https://mp.betabh.in/marketplace/api/',
		blobBaseUrl: 'https://dev-qa-bighaat-media-cdn.azureedge.net/',
	},
	prod: {
		apiBaseUrl: 'https://api.bighaat.com/marketplace/api/',
		blobBaseUrl: 'https://bighaat-prodv2-media-cdn.azureedge.net/',
	},
};

export const env = 'prod';

export const { apiBaseUrl, blobBaseUrl, pointingEnv = env } = environments[env];
